<template>
  <div class="layout-login">
    <div class="card-login">
      <LogoWhite />
      <!-- IF ERROR MESSAGE -->

      <div class="login-signin" v-if="this.errorMessage">
        <div>
          <div class="login-header">
            <Subtitle1 color="gray-2">
              {{ this.errorMessage }}
            </Subtitle1>
          </div>
        </div>
      </div>

      <div class="login-signin" v-if="userData">
        <div>
          <div class="login-header">
            <Heading5
              >Connexion au compte de {{ userData.firstName }}
              {{ userData.lastName }}</Heading5
            >
            <Subtitle1 color="gray-2">
              Nous avons envoyé un SMS au responsable sécurité Kermony afin de
              valider votre demande.
            </Subtitle1>
          </div>
        </div>
        <form
          class="form"
          id="kt_login_forgot_form"
          @submit.prevent="javascript: void 0;"
        >
          <Pincode
            v-model:phoneToken="adminToken"
            :onFulfilled="submitAdminAuthentication"
          />

          <div style="margin-top: 16px; justify-content: center; display: flex">
            <Button
              theme="primary"
              size="large"
              :shadow="true"
              @click="submitAdminAuthentication()"
              >CONFIRMER</Button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/login.min.css";

import authApi from "@/api/auth";
import LogoWhite from "@/views/Auth/Login/LogoWhite";
import Heading5 from "@/components/Texts/Heading5";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import Pincode from "@/views/Auth/Login/Pincode";

export default {
  name: "AdminLogin",
  components: { Pincode, Button, Subtitle1, Heading5, LogoWhite },
  data() {
    return {
      userData: null,
      userToken: null,
      adminToken: null,
      errorMessage: null,
    };
  },

  mounted() {
    this.userToken = this.$route.params.userToken;

    if (!this.userToken) return this.$router.push("/");

    // Prevent twice mounted call.
    this.verifyUserToken();
    document.querySelector(".grecaptcha-badge").style.visibility = "visible";
  },

  unmounted() {
    document.querySelector(".grecaptcha-badge").style.visibility = "hidden";
  },

  methods: {
    verifyUserToken() {
      authApi
        .requestAdminAuthentication({ userToken: this.userToken })
        .then((response) => {
          this.userData = response;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "[requestAdminAuthentication]",
              "Error from API:",
              error.response.data.message
            );
            this.errorMessage = error.response.data.message;
          }
        });
    },

    submitAdminAuthentication() {
      // Reset errors
      this.errorMessage = null;

      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha
          .execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_API_KEY, {
            action: "submit",
          })
          // eslint-disable-next-line no-undef,no-unused-vars
          .then((token) => {
            authApi
              .requestAdminAuthentication({
                userToken: this.userToken,
                adminToken: this.adminToken,
                recaptchaToken: token,
              })
              .then((response) => {
                localStorage.removeItem("user");
                this.$store.dispatch("logout").then(() => {
                  this.$store
                    .dispatch("validateLogin", response)
                    .then(() => {
                      this.$router.push("/");
                    })
                    .catch(() => {
                      this.errorMessage =
                        "Impossible de vous connecter, merci de contacter le support.";
                    });
                });
              })
              .catch((error) => {
                if (error.response) {
                  console.log(
                    "[requestAdminAuthentication]",
                    "Error from API:",
                    error.response.data.message
                  );
                  this.errorMessage = error.response.data.message;
                }
              });
          });
      });
    },
  },
};
</script>

<style scoped>
.layout-login {
  height: 100%;
  width: 100%;
  background: url(/filigrane.png) #fbf9fa;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-login .card-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  position: absolute;
  width: 472px;
  /*height: 500px;*/
  /*min-height: 400px;*/

  /* Kermony/Blanc */

  background: #ffffff;
  /* Card shadow */

  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
  max-width: calc(100% - 32px);
  margin: 16px;
}

.layout-login .login-header {
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: center;
}

.layout-login .login-signin,
.layout-login .login-forgot,
.layout-login .login-forgot-success {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.layout-login form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.layout-login .no-account {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.container-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.container-field input {
  background: #ffffff;
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  padding: 8px 0px 8px 8px;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #21244e;
}

.container-field input:focus {
  outline: none;
}

.layout-login .link {
  color: var(--kermony-office-rose);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
</style>
